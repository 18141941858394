import styled from "styled-components";

export const NotFoundContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 3rem; */
  align-items: center;
  height: 70vh;
`;
